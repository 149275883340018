var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-5 mt-5 justify-center"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("User")])]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[function (v) { return !!v || 'Name is required'; }],"required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('v-text-field',{attrs:{"label":"Email","rules":[
                        function (v) { return !!v || 'Email is required'; },
                        function (v) { return /.+@.+\..+/.test(v) || 'E-mail must be valid'; } ],"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-container',{staticClass:"d-flex d-row"},[_c('v-select',{attrs:{"items":_vm.roles,"label":"Role","rules":[function (v) { return !!v || 'Role is required'; }],"required":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help ")])]}}])},[(_vm.user.role === 'CENOTERO_BASIC')?_c('span',[_vm._v("A Cenotero (basic) is the most restrictive access role, has the same access as non-registered user, but can be given explicit permission to certain themes and/or cenotes")]):_vm._e(),(_vm.user.role === 'CENOTERO_ADVANCED')?_c('span',[_vm._v("A Cenotero (advanced) can see all cenotes (except when explicitly forbidden), but can't make any changes on the underlying data")]):_vm._e(),(_vm.user.role === 'RESEARCHER')?_c('span',[_vm._v("A Researcher has full access to everything in the platform an administrator has access to, but can't delete any data not created by himself")]):_vm._e(),(_vm.user.role === 'ADMIN')?_c('span',[_vm._v("An Administrator has full access to every non-sensitive datapoint in the platform with no restrictions")]):_vm._e()])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!this.valid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }