




































































import CenoteDTO from '@/models/CenoteDTO';
import UserDTO, { UserRole } from '@/models/UserDTO';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        user: UserDTO,
        cenote: [],
    },
})
export default class EditPermissionsDialog extends Vue {
    cenoteWhiteList: string[] = [];
    cenoteBlackList: string[] = [];
    themeWhiteList: string[] = [];
    themeBlackList: string[] = [];

    themes = [
        'LOCATION',
        'GEOREFERENCE',
        'CULTURAL',
        'GEOMORPHOLOGY',
        'BIODIVERSITY',
        'DISTURBANCE',
        'TOURISM',
        'DIVING',
        'ORGANIZATION',
        'REGULATION',
        'WATER',
    ];
    dialog = false;
    valid = false;
    roles = Object.values(UserRole);

    cenoteToDisplay(cenote: CenoteDTO): string {
        return cenote.id + ' - ' + cenote.name;
    }

    cenoteDisplayToId(display: string): string {
        return display.split(' - ')[0];
    }

    filteredVariableBlackList(): string[] {
        return this.themes.filter((t) => {
            return !this.themeWhiteList.includes(t);
        });
    }

    filteredVariableWhiteList(): string[] {
        return this.themes.filter((t) => {
            return !this.themeBlackList.includes(t);
        });
    }

    filteredCenoteBlackList(): string[] {
        return this.$props.cenote
            .map(this.cenoteToDisplay)
            .filter((c: string) => {
                return !this.cenoteWhiteList.includes(c);
            });
    }

    filteredCenoteWhiteList(): string[] {
        return this.$props.cenote
            .map(this.cenoteToDisplay)
            .filter((c: string) => {
                return !this.cenoteBlackList.includes(c);
            });
    }

    cenoteIdToDisplay(id: string): string {
        for (var cenote of this.$props.cenote) {
            if (cenote.id == id) return this.cenoteToDisplay(cenote);
        }
        return id + ' - Not Found';
    }

    async getCenotes(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            let generator = RemoteServices.cenotesGenerator(30);
            for await (let batch of generator) {
                this.$props.cenote.push(...batch);
            }
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }

    async created(): Promise<void> {
        if (this.$props.user.themesWhiteList) {
            this.themeWhiteList = this.$props.user.themesWhiteList;
        }
        if (this.$props.user.themesBlackList) {
            this.themeBlackList = this.$props.user.themesBlackList;
        }
        if (this.$props.user.cenoteWhiteList) {
            this.cenoteWhiteList = this.$props.user.cenoteWhiteList.map(
                this.cenoteIdToDisplay,
            );
        }
        if (this.$props.user.cenoteBlackList) {
            this.cenoteBlackList = this.$props.user.cenoteBlackList.map(
                this.cenoteIdToDisplay,
            );
        }
    }

    save(): void {
        this.$props.user.themesWhiteList = this.themeWhiteList;
        this.$props.user.themesBlackList = this.themeBlackList;
        this.$props.user.cenoteWhiteList = this.cenoteWhiteList.map(
            this.cenoteDisplayToId,
        );
        this.$props.user.cenoteBlackList = this.cenoteBlackList.map(
            this.cenoteDisplayToId,
        );

        this.$emit('onSave');
        this.dialog = false;
    }
}
