var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"table mx-8"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('v-text-field',{staticClass:"mx-2",attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Filters ")]),_c('v-expansion-panel-content',[_c('v-select',{attrs:{"items":_vm.roles,"label":"Roles","multiple":"","chips":"","hint":"User roles","persistent-hint":""},model:{value:(_vm.filterRole),callback:function ($$v) {_vm.filterRole=$$v},expression:"filterRole"}})],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('edit-user-dialog',{attrs:{"user":item},on:{"onSave":function($event){return _vm.updateUser(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 action-button",attrs:{"color":"green","data-cy":"editUser"}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)}),_c('delete-dialog',{on:{"onConfirm":function($event){return _vm.deleteUser(item)}}}),_c('edit-permissions-dialog',{attrs:{"user":item,"cenote":_vm.cenotes},on:{"onSave":function($event){return _vm.updateUser(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                            item.role === 'CENOTERO_BASIC' ||
                            item.role === 'CENOTERO_ADVANCED'
                        )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 action-button",attrs:{"color":"blue","data-cy":"editPermissions"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-cog")]):_vm._e()]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }