















































































import DeleteDialog from '@/components/admin/DeleteDialog.vue';
import EditPermissionsDialog from '@/components/admin/EditPermissionsDialog.vue';
import EditUserDialog from '@/components/admin/EditUserDialog.vue';
import UserDTO from '@/models/UserDTO';
import CenoteDTO from '@/models/CenoteDTO';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        EditUserDialog,
        DeleteDialog,
        EditPermissionsDialog,
    },
})
export default class Users extends Vue {
    headers = [
        { text: 'User ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'action' },
    ];
    roles = ['ADMIN', 'RESEARCHER', 'CENOTERO_ADVANCED', 'CENOTERO_BASIC'];
    search = '';
    flag = false; //This flag will be used to get the User and Cenote data and only load the editPermissions dialog after the view gets all the data
    filterRole: string[] = [];
    users: UserDTO[] = [];
    cenotes: CenoteDTO[] = [];

    get filteredUsers(): UserDTO[] {
        return this.users.filter(
            (u) => !this.filterRole.length || this.filterRole.includes(u.role),
        );
    }

    async getUsers(): Promise<void> {
        let generator = RemoteServices.usersGenerator(15);
        for await (let batch of generator) {
            this.users.push(...batch);
        }
    }

    async getCenotes(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            let generator = RemoteServices.cenotesGenerator(30);
            for await (let batch of generator) {
                this.cenotes.push(...batch);
            }
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }

    async created(): Promise<void> {
        await this.$store.dispatch('loading');

        await this.getCenotes();

        await this.getUsers().catch(async (error) => {
            await this.$store.dispatch('error', error);
        });
        await this.$store.dispatch('clearLoading');
    }

    async updateUser(user: UserDTO): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            await RemoteServices.updateUser(user);
        } catch (error) {
            // TODO: revert to original value in case of failure
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }

    async deleteUser(user: UserDTO): Promise<void> {
        await RemoteServices.deleteUser(user.id);
        this.users = this.users.filter((u) => u.id != user.id);
    }
}
