





























































































import UserDTO, { UserRole } from '@/models/UserDTO';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        user: UserDTO,
    },
})
export default class EditUserDialog extends Vue {
    dialog = false;
    valid = false;
    roles = Object.values(UserRole);

    save(): void {
        this.$emit('onSave');
        this.dialog = false;
    }
}
